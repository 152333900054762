import React from "react";
import "./Skills.css";
import javaIcon from "./skills-icons/icon-java.png";
import htmlIcon from "./skills-icons/icon-html.png";
import cssIcon from "./skills-icons/icon-css.png";
import jsIcon from "./skills-icons/icon-js.png";
import reactIcon from "./skills-icons/icon-react.png";
import pythonIcon from "./skills-icons/icon-python.png";
import flaskIcon from "./skills-icons/icon-flask.png";
import djangoIcon from "./skills-icons/icon-django.png";
import springIcon from "./skills-icons/icon-spring.png";
import mavenIcon from "./skills-icons/icon-maven.webp";
import gitIcon from "./skills-icons/icon-git.png";
import scikitIcon from "./skills-icons/icon-scikit.png";
import linuxIcon from "./skills-icons/icon-linux.png";
import oracleIcon from "./skills-icons/icon-oracle.png";
import jupyterIcon from "./skills-icons/icon-jupyter.png";
import dockerIcon from "./skills-icons/icon-docker.png";
import fastapiIcon from "./skills-icons/icon-fastapi.png";
import agileIcon from "./skills-icons/icon-agile.png";
import mysqlIcon from "./skills-icons/icon-mysql.png";
import jwtIcon from "./skills-icons/icon-jwt.png";
import junitIcon from "./skills-icons/icon-junit.png";
import pandasIcon from "./skills-icons/icon-pandas.png";

function Skills({ language }) {
  const texts = {
    en: {
      skills: "SKILLS",
    },
    pt: {
      skills: "TECNOLOGIAS",
    },
  };

  const currentTexts = texts[language];

  return (
    <div className="container-skills">
      <h2>{currentTexts.skills}</h2>
      <div className="skills-list">

        <div className="skills-list-item">
          <img src={jsIcon} alt="JS" />
          <span>JavaScript</span>
        </div>

        <div className="skills-list-item">
          <img src={reactIcon} alt="React JS" />
          <span>React JS</span>
        </div>

        <div className="skills-list-item">
          <img src={pythonIcon} alt="Python" />
          <span>Python</span>
        </div>

        <div className="skills-list-item">
          <img src={fastapiIcon} alt="Fast API" />
          <span>Fast API</span>
        </div>

        <div className="skills-list-item">
          <img src={flaskIcon} alt="Flask" />
          <span>Flask</span>
        </div>

        <div className="skills-list-item">
          <img src={djangoIcon} alt="Django" />
          <span>Django</span>
        </div>

        <div className="skills-list-item">
          <img src={pandasIcon} alt="Pandas" />
          <span>Pandas</span>
        </div>

        <div className="skills-list-item">
          <img src={scikitIcon} alt="Scikit-learn" />
          <span>scikit-learn</span>
        </div>

        <div className="skills-list-item">
          <img src={javaIcon} alt="Java" />
          <span>Java</span>
        </div>
        <div className="skills-list-item">
          <img src={junitIcon} alt="JUnit" />
          <span>JUnit 5</span>
        </div>
        <div className="skills-list-item">
          <img src={springIcon} alt="Spring" />
          <span>Spring Boot</span>
        </div>
        <div className="skills-list-item">
          <img src={dockerIcon} alt="Docker" />
          <span>Docker</span>
        </div>

        <div className="skills-list-item">
          <img src={jupyterIcon} alt="Jupyter Notebook" />
          <span>Jupyter</span>
        </div>

        <div className="skills-list-item">
          <img src={mavenIcon} alt="Maven Apache" />
          <span>Maven</span>
        </div>

        <div className="skills-list-item">
          <img src={jwtIcon} alt="JWT" />
          <span>JWT</span>
        </div>

        <div className="skills-list-item">
          <img src={gitIcon} alt="Git" />
          <span>Git</span>
        </div>

        <div className="skills-list-item">
          <img src={linuxIcon} alt="Linux" />
          <span>Linux</span>
        </div>
        <div className="skills-list-item">
          <img src={oracleIcon} alt="Oracle" />
          <span>Oracle SQL</span>
        </div>
        <div className="skills-list-item">
          <img src={mysqlIcon} alt="MYSQL" />
          <span>MYSQL</span>
        </div>
        <div className="skills-list-item">
          <img src={agileIcon} alt="Agile" />
          <span>Agile</span>
        </div>
      </div>
    </div>
  );
}

export default Skills;
