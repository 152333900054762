import React from "react";
import "./Projects.css";
import githubIcon from "./github-icon.png";
import openLinkIcon from "./open-link.png";
import rightArrow from "./right-arrow-icon.png";
import leftArrow from "./left-arrow-icon.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import githubDarkIcon from "./github-dark-icon.png";
import openLinkDarkIcon from "./open-link-dark.png";

function Projects({ language, darkMode }) {
  const settings = {
    dots: true,
    speed: 350,
    arrows: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    infinite: true,
    responsive: [
      {
        breakpoint: 805,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  let currentTexts;
  if (language === "en") {
    currentTexts = {
      title: "PROJECTS",
      project1: {
        title: "The Boys - RESTful API",
        description:
          "RESTful API that operates on the character database of The Boys series, allowing the creation, retrieval, update and deletion of character records.",
        technologies: ["Java", "Maven", "MVC Pattern"],
        githubLink: "https://github.com/mthaugusto/the-boys-api",
        liveLink: null,
      },
      project2: {
        title: "Library Management System",
        description:
          "A JavaFX simple book management system that allows users to add, delete, and update book information. It also includes functionality for managing authors.",
        technologies: ["Java FX", "Scene Builder", "Oracle SQL"],
        githubLink: "https://github.com/mthaugusto/javafx-library",
        liveLink: null,
      },
      project3: {
        title: "Library Management System",
        description:
          "This is a simple library management system built using HTML, CSS, JavaScript, jQuery, and Bootstrap. It allows users to add, edit, and remove books from a list.",
        technologies: ["Javascript", "jQUery", "Boostrap"],
        githubLink:
          "https://github.com/mthaugusto/library-management-javascript",
        liveLink: "https://library-management-javascript.vercel.app/",
      },
      project4: {
        title: "Blackjack JavaFX Game",
        description:
          "A JavaFX implementation of the classic card game Blackjack. Play against the computer dealer and aim to get as close to 21 points as possible.",
        technologies: ["Java", "JavaFX", "FXML"],
        githubLink: "https://github.com/mthaugusto/blackjack-java",
        liveLink: null,
      },
      project5: {
        title: "Wi-Fi QR Code Generator",
        description:
          "A simple web app for generating QR codes for Wi-Fi networks. Contains SSID, password, and security protocol for easy device connection.",
        technologies: ["Python", "Flask", "QRCode"],
        githubLink:
          "https://github.com/mthaugusto/qr-code-generator-python-flask",
        liveLink: null,
      },
      project6: {
        title: "Prison Break Data Analysis",
        description:
          "An analysis project focusing on helicopter prison escapes. Extracts insights data including trends over time and the most common countries involved.",
        technologies: ["Python", "Pandas", "Matplotlib"],
        githubLink: "https://github.com/mthaugusto/Prison-Break-Analysis",
        liveLink: null,
      },
      project7: {
        title: "Personal Finance App",
        description:
          "A personal finance application developed with React Native, allowing users to view their balance, recent transactions, and perform various financial actions.",
        technologies: ["React Native", "Expo", "JavaScript"],
        githubLink: "https://github.com/mthaugusto/finance-app-expo-matheus",
        liveLink: null,
      },
      project8: {
        title: "Forest Fires in Brazil Analysis",
        description:
          "This project performs an analysis of forest fires recorded in Brazil between the years 1998 and 2017, answering various analytical questions about them.",
        technologies: ["Python", "Pandas", "Seaborn"],
        githubLink:
          "https://github.com/mthaugusto/forest-fires-in-brazil-data-analysis",
        liveLink:
          "https://colab.research.google.com/drive/1SXOc7n3iD-9jIosPi97VrghsarZLS8z8",
      },
      project9: {
        title: "Gradient Background Generator",
        description:
          "A simple linear gradient background generator. Users can select two colors using color pickers, randomize the colors and copy the generated CSS.",
        technologies: ["HTML", "CSS", "Vanilla JS"],
        githubLink: "https://github.com/mthaugusto/gradient-colour-generator",
        liveLink: "https://linear-gradient-colour-generator.vercel.app/",
      },
      project10: {
        title: "Superstore Sales Data Analysis",
        description:
          "This project conducts comprehensive data exploration and hypothesis testing to derive actionable insights for optimizing sales strategies in a superstore.",
        technologies: ["Python", "Pandas", "Matplotlib"],
        githubLink:
          "https://github.com/mthaugusto/superstore-sales-data-analysis",
        liveLink:
          "https://colab.research.google.com/drive/1rElhNrBS7tTo1RRyM3uk4B0U2x7w1vYv#scrollTo=lOjSXNmambMe",
      },
        project11: {
        title: "Spring Boot Hotel Management",
        description:
          "An application developed in Java using Spring Boot, Spring Data JPA and Hibernate. It offers a solution for efficiently and scalably managing hotels, rooms, and guests.",
        technologies: ["Java", "Spring Boot", "Hibernate"],
        githubLink:
          "https://github.com/mthaugusto/hotel-management-spring-boot",
        liveLink:
          null,
      },
      project12: {
        title: "Employee JavaFX Management",
        description:
          "An application developed in Java with a JavaFX interface. It provides a solution for managing employee information, including registration, visualization, and data update.",
        technologies: ["JavaFX", "FXML", "MySQL"],
        githubLink:
          "https://github.com/mthaugusto/employee-management-javafx",
        liveLink:
          null,
      }
    };
  } else if (language === "pt") {
    currentTexts = {
      title: "PROJETOS",
      project1: {
        title: "The Boys - RESTful API",
        description:
          "API RESTful que opera no banco de dados de personagens da série The Boys, permitindo a criação, recuperação, atualização e exclusão de registros de personagens.",
        technologies: ["Java", "Maven", "Padrão MVC"],
        githubLink: "https://github.com/mthaugusto/the-boys-api",
        liveLink: null,
      },
      project2: {
        title: "Gerenciamento de Bibliotecas",
        description:
          "Sistema de gerenciamento de livros em JavaFX que permite aos usuários adicionar, excluir e atualizar informações sobre livros, além de gerenciar autores.",
        technologies: ["Java FX", "Scene Builder", "Oracle SQL"],
        githubLink: "https://github.com/mthaugusto/javafx-library",
        liveLink: null,
      },
      project3: {
        title: "Gerenciamento de Livrarias",
        description:
          "Sistema simples de gerenciamento de biblioteca construído usando JavaScript, jQuery e Bootstrap. Ele permite aos usuários adicionar, editar e remover livros de uma lista.",
        technologies: ["Javascript", "jQUery", "Boostrap"],
        githubLink:
          "https://github.com/mthaugusto/library-management-javascript",
        liveLink: "https://library-management-javascript.vercel.app/",
      },
      project4: {
        title: "Blackjack/21 em JavaFX",
        description:
          "Implementação em JavaFX do clássico jogo de cartas Blackjack/21. Jogue contra o dealer computadorizado e tente chegar o mais perto possível de 21 pontos.",
        technologies: ["Java", "JavaFX", "FXML"],
        githubLink: "https://github.com/mthaugusto/blackjack-java",
        liveLink: null,
      },
      project5: {
        title: "Gerador de QR Code Wi-Fi",
        description:
          "Um aplicativo web simples para gerar códigos QR para redes Wi-Fi. Contém SSID, senha e protocolo de segurança para facilitar a conexão de dispositivos.",
        technologies: ["Python", "Flask", "qrcode"],
        githubLink:
          "https://github.com/mthaugusto/qr-code-generator-python-flask",
        liveLink: null,
      },
      project6: {
        title: "Análise de Dados - Fugas de Prisão",
        description:
          "Um projeto de análise focado em fugas de prisão de helicóptero. Extrai insights dos dados, incluindo tendências ao longo do tempo e os países comumente envolvidos.",
        technologies: ["Python", "Pandas", "Matplotlib"],
        githubLink: "https://github.com/mthaugusto/Prison-Break-Analysis",
        liveLink: null,
      },
      project7: {
        title: "Aplicativo de Finanças Pessoais",
        description:
          "Aplicativo de finanças pessoais desenvolvido com React Native, permitindo que os usuários visualizem seu saldo, transações recentes e realizem ações financeiras.",
        technologies: ["React Native", "Expo", "JavaScript"],
        githubLink: "https://github.com/mthaugusto/finance-app-expo-matheus",
        liveLink: null,
      },
      project8: {
        title: "Incêndios Florestais no Brasil",
        description:
          "Este projeto realiza uma análise de dados dos incêndios florestais registrados no Brasil entre os anos de 1998 e 2017, respondendo a várias perguntas analíticas sobre eles.",
        technologies: ["Python", "Pandas", "Seaborn"],
        githubLink:
          "https://github.com/mthaugusto/forest-fires-in-brazil-data-analysis",
        liveLink:
          "https://colab.research.google.com/drive/1SXOc7n3iD-9jIosPi97VrghsarZLS8z8",
      },
      project9: {
        title: "Gerador de Fundo Gradiente",
        description:
          "Um simples gerador de fundo gradiente linear. Os usuários podem selecionar duas cores usando seletores de cores, aleatorizar as cores e copiar o CSS gerado.",
        technologies: ["HTML", "CSS", "Vanilla JS"],
        githubLink: "https://github.com/mthaugusto/gradient-colour-generator",
        liveLink: "https://linear-gradient-colour-generator.vercel.app/",
      },
      project10: {
        title: "Vendas de Loja de Departamento",
        description:
          "Este projeto realiza uma exploração abrangente de dados e testes de hipóteses para obter insights significativos para otimizar estratégias de vendas em uma loja de departamento.",
        technologies: ["Python", "Pandas", "Matplotlib"],
        githubLink:
          "https://github.com/mthaugusto/analise-dados-vendas-superstore",
        liveLink:
          "https://colab.research.google.com/drive/1rElhNrBS7tTo1RRyM3uk4B0U2x7w1vYv#scrollTo=lOjSXNmambMe",
      },
        project11: {
        title: "Gestão de Hotéis com Spring Boot",
        description:
          "Aplicação desenvolvida em Java utilizando o Spring Boot, Spring Data JPA e Hibernate. Oferece uma solução para gerenciar hotéis, quartos e hóspedes de forma eficiente e escalável.",
        technologies: ["Java", "Spring Boot", "Hibernate"],
        githubLink:
          "https://github.com/mthaugusto/hotel-management-spring-boot",
        liveLink:
          null,
      },
      project12: {
        title: "Employee JavaFX Management",
        description:
          "Uma aplicação desenvolvida em Java com interface JavaFX. Ela fornece uma solução para gerenciar informações de funcionários, incluindo registro, visualização e atualização de dados.",
        technologies: ["JavaFX", "FXML", "MySQL"],
        githubLink:
          "https://github.com/mthaugusto/employee-management-javafx",
        liveLink:
          null,
      }
    };
  }

  return (
    <div className="div-projects">
      <h2>{currentTexts.title}</h2>
      <div className="projects">
        <Slider {...settings}>
          <div className="project-card">
            <h3>{currentTexts.project10.title}</h3>
            <p className="project-card-description">
              {currentTexts.project10.description}
            </p>
            <div className="technologies">
              <p>{currentTexts.project10.technologies[0]}</p>
              <p>{currentTexts.project10.technologies[1]}</p>
              <p>{currentTexts.project10.technologies[2]}</p>
            </div>
            <div className="icons">
              <a
                className="icon-link"
                href={currentTexts.project10.githubLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={darkMode ? githubDarkIcon : githubIcon}
                  alt="GitHub icon"
                />
              </a>
              {currentTexts.project10.liveLink && (
                <a
                  className="icon-link"
                  href={currentTexts.project10.liveLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={darkMode ? openLinkDarkIcon : openLinkIcon}
                    alt="Live link icon"
                  />
                </a>
              )}
            </div>
          </div>
          <div className="project-card">
            <h3>{currentTexts.project12.title}</h3>
            <p className="project-card-description">
              {currentTexts.project12.description}
            </p>
            <div className="technologies">
              <p>{currentTexts.project12.technologies[0]}</p>
              <p>{currentTexts.project12.technologies[1]}</p>
              <p>{currentTexts.project12.technologies[2]}</p>
            </div>
            <div className="icons">
              <a
                className="icon-link"
                href={currentTexts.project12.githubLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={darkMode ? githubDarkIcon : githubIcon}
                  alt="GitHub icon"
                />
              </a>
              {currentTexts.project12.liveLink && (
                <a
                  className="icon-link"
                  href={currentTexts.project12.liveLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={darkMode ? openLinkDarkIcon : openLinkIcon}
                    alt="Live link icon"
                  />
                </a>
              )}
            </div>
          </div>
          <div className="project-card">
            <h3>{currentTexts.project11.title}</h3>
            <p className="project-card-description">
              {currentTexts.project11.description}
            </p>
            <div className="technologies">
              <p>{currentTexts.project11.technologies[0]}</p>
              <p>{currentTexts.project11.technologies[1]}</p>
              <p>{currentTexts.project11.technologies[2]}</p>
            </div>
            <div className="icons">
              <a
                className="icon-link"
                href={currentTexts.project11.githubLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={darkMode ? githubDarkIcon : githubIcon}
                  alt="GitHub icon"
                />
              </a>
              {currentTexts.project11.liveLink && (
                <a
                  className="icon-link"
                  href={currentTexts.project11.liveLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={darkMode ? openLinkDarkIcon : openLinkIcon}
                    alt="Live link icon"
                  />
                </a>
              )}
            </div>
          </div>
          <div className="project-card">
            <h3>{currentTexts.project1.title}</h3>
            <p className="project-card-description">
              {currentTexts.project1.description}
            </p>
            <div className="technologies">
              <p>{currentTexts.project1.technologies[0]}</p>
              <p>{currentTexts.project1.technologies[1]}</p>
              <p>{currentTexts.project1.technologies[2]}</p>
            </div>
            <div className="icons">
              <a
                className="icon-link"
                href={currentTexts.project1.githubLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={darkMode ? githubDarkIcon : githubIcon}
                  alt="GitHub icon"
                />
              </a>
              {currentTexts.project1.liveLink && (
                <a
                  className="icon-link"
                  href={currentTexts.project1.liveLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={darkMode ? openLinkDarkIcon : openLinkIcon}
                    alt="Live link icon"
                  />
                </a>
              )}
            </div>
          </div>
          <div className="project-card">
            <h3>{currentTexts.project7.title}</h3>
            <p className="project-card-description">
              {currentTexts.project7.description}
            </p>
            <div className="technologies">
              <p>{currentTexts.project7.technologies[0]}</p>
              <p>{currentTexts.project7.technologies[1]}</p>
              <p>{currentTexts.project7.technologies[2]}</p>
            </div>
            <div className="icons">
              <a
                className="icon-link"
                href={currentTexts.project7.githubLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={darkMode ? githubDarkIcon : githubIcon}
                  alt="GitHub icon"
                />
              </a>
              {currentTexts.project7.liveLink && (
                <a
                  className="icon-link"
                  href={currentTexts.project7.liveLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={darkMode ? openLinkDarkIcon : openLinkIcon}
                    alt="Live link icon"
                  />
                </a>
              )}
            </div>
          </div>
          <div className="project-card">
            <h3>{currentTexts.project6.title}</h3>
            <p className="project-card-description">
              {currentTexts.project6.description}
            </p>
            <div className="technologies">
              <p>{currentTexts.project6.technologies[0]}</p>
              <p>{currentTexts.project6.technologies[1]}</p>
              <p>{currentTexts.project6.technologies[2]}</p>
            </div>
            <div className="icons">
              <a
                className="icon-link"
                href={currentTexts.project6.githubLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={darkMode ? githubDarkIcon : githubIcon}
                  alt="GitHub icon"
                />
              </a>
              {currentTexts.project6.liveLink && (
                <a
                  className="icon-link"
                  href={currentTexts.project6.liveLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={darkMode ? openLinkDarkIcon : openLinkIcon}
                    alt="Live link icon"
                  />
                </a>
              )}
            </div>
          </div>
          <div className="project-card">
            <h3>{currentTexts.project5.title}</h3>
            <p className="project-card-description">
              {currentTexts.project5.description}
            </p>
            <div className="technologies">
              <p>{currentTexts.project5.technologies[0]}</p>
              <p>{currentTexts.project5.technologies[1]}</p>
              <p>{currentTexts.project5.technologies[2]}</p>
            </div>
            <div className="icons">
              <a
                className="icon-link"
                href={currentTexts.project5.githubLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={darkMode ? githubDarkIcon : githubIcon}
                  alt="GitHub icon"
                />
              </a>
              {currentTexts.project5.liveLink && (
                <a
                  className="icon-link"
                  href={currentTexts.project5.liveLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={darkMode ? openLinkDarkIcon : openLinkIcon}
                    alt="Live link icon"
                  />
                </a>
              )}
            </div>
          </div>

          <div className="project-card">
            <h3>{currentTexts.project4.title}</h3>
            <p className="project-card-description">
              {currentTexts.project4.description}
            </p>
            <div className="technologies">
              <p>{currentTexts.project4.technologies[0]}</p>
              <p>{currentTexts.project4.technologies[1]}</p>
              <p>{currentTexts.project4.technologies[2]}</p>
            </div>
            <div className="icons">
              <a
                className="icon-link"
                href={currentTexts.project4.githubLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={darkMode ? githubDarkIcon : githubIcon}
                  alt="GitHub icon"
                />
              </a>
              {currentTexts.project4.liveLink && (
                <a
                  className="icon-link"
                  href={currentTexts.project4.liveLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={darkMode ? openLinkDarkIcon : openLinkIcon}
                    alt="Live link icon"
                  />
                </a>
              )}
            </div>
          </div>

          <div className="project-card">
            <h3>{currentTexts.project9.title}</h3>
            <p className="project-card-description">
              {currentTexts.project9.description}
            </p>
            <div className="technologies">
              <p>{currentTexts.project9.technologies[0]}</p>
              <p>{currentTexts.project9.technologies[1]}</p>
              <p>{currentTexts.project9.technologies[2]}</p>
            </div>
            <div className="icons">
              <a
                className="icon-link"
                href={currentTexts.project9.githubLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={darkMode ? githubDarkIcon : githubIcon}
                  alt="GitHub icon"
                />
              </a>
              {currentTexts.project9.liveLink && (
                <a
                  className="icon-link"
                  href={currentTexts.project9.liveLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={darkMode ? openLinkDarkIcon : openLinkIcon}
                    alt="Live link icon"
                  />
                </a>
              )}
            </div>
          </div>
          <div className="project-card">
            <h3>{currentTexts.project3.title}</h3>
            <p className="project-card-description">
              {currentTexts.project3.description}
            </p>
            <div className="technologies">
              <p>{currentTexts.project3.technologies[0]}</p>
              <p>{currentTexts.project3.technologies[1]}</p>
              <p>{currentTexts.project3.technologies[2]}</p>
            </div>
            <div className="icons">
              <a
                className="icon-link"
                href={currentTexts.project3.githubLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={darkMode ? githubDarkIcon : githubIcon}
                  alt="GitHub icon"
                />
              </a>
              {currentTexts.project3.liveLink && (
                <a
                  className="icon-link"
                  href={currentTexts.project3.liveLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={darkMode ? openLinkDarkIcon : openLinkIcon}
                    alt="Live link icon"
                  />
                </a>
              )}
            </div>
          </div>
          <div className="project-card">
            <h3>{currentTexts.project8.title}</h3>
            <p className="project-card-description">
              {currentTexts.project8.description}
            </p>
            <div className="technologies">
              <p>{currentTexts.project8.technologies[0]}</p>
              <p>{currentTexts.project8.technologies[1]}</p>
              <p>{currentTexts.project8.technologies[2]}</p>
            </div>
            <div className="icons">
              <a
                className="icon-link"
                href={currentTexts.project8.githubLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={darkMode ? githubDarkIcon : githubIcon}
                  alt="GitHub icon"
                />
              </a>
              {currentTexts.project8.liveLink && (
                <a
                  className="icon-link"
                  href={currentTexts.project8.liveLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={darkMode ? openLinkDarkIcon : openLinkIcon}
                    alt="Live link icon"
                  />
                </a>
              )}
            </div>
          </div>

          <div className="project-card">
            <h3>{currentTexts.project2.title}</h3>
            <p className="project-card-description">
              {currentTexts.project2.description}
            </p>
            <div className="technologies">
              <p>{currentTexts.project2.technologies[0]}</p>
              <p>{currentTexts.project2.technologies[1]}</p>
              <p>{currentTexts.project2.technologies[2]}</p>
            </div>
            <div className="icons">
              <a
                className="icon-link"
                href={currentTexts.project2.githubLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={darkMode ? githubDarkIcon : githubIcon}
                  alt="GitHub icon"
                />
              </a>
              {currentTexts.project2.liveLink && (
                <a
                  className="icon-link"
                  href={currentTexts.project2.liveLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={darkMode ? openLinkDarkIcon : openLinkIcon}
                    alt="Live link icon"
                  />
                </a>
              )}
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
}

export default Projects;
