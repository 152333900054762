import React from "react";
import "./AboutMe.css";
import githubIcon from "./icon-github2.png";
import linkedinIcon from "./icon-linkedin2.png";
import { Link } from "react-scroll";

function AboutMe({ language }) {
  const texts = {
    en: {
      title: "Hi. I am",
      name: "Matheus.",
      profession: "Software Developer",
      description:
        "My name is Matheus Leite and I'm a full-stack developer with experience in developing software applications, web applications, REST APIs, supervised AI trained models, and much more.",
      button: "Get in touch",
    },
    pt: {
      title: "Olá. Eu sou o",
      name: "Matheus.",
      profession: "Desenvolvedor de Software",
      description:
        "Meu nome é Matheus Leite e sou desenvolvedor full-stack com experiência no desenvolvimento de aplicativos software e web, APIs REST, modelos IA de treinamento supervisionado e muito mais.",
      button: "Entrar em contato",
    },
  };

  const currentTexts = texts[language];

  return (
    <div className="about-me-container">
      <div className="about-me-left">
        <h2>
          {currentTexts.title}{" "}
          <span className="matheusColour">{currentTexts.name}</span>
        </h2>
      </div>
      <div className="about-me-right">
        <h3>{currentTexts.profession}</h3>
        <p>{currentTexts.description}</p>
        <Link
          to="contact-container"
          spy={true}
          smooth={true}
          offset={-30}
          duration={500}
        >
          <button className="get-in-touch-btn">{currentTexts.button}</button>
        </Link>
        <div className="iconsMobile">
          <a
            href="https://www.linkedin.com/in/augustomath/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              class="linkedinIcon"
              src={linkedinIcon}
              alt="Ícone Linkedin"
            ></img>
          </a>
          <a
            href="https://github.com/mthaugusto/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img class="githubIcon" src={githubIcon} alt="Ícone Github"></img>
          </a>
        </div>
      </div>
    </div>
  );
}

export default AboutMe;
