import React, { useState } from "react";
import "./ScrollToTopButton.css";
import arrowUpIcon from "./icon/icon-scroll.png";

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 455) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisibility);

  return (
    <div>
      {isVisible && (
        <div>
          <button className="scroll-to-top-btn" onClick={scrollToTop}>
            <img src={arrowUpIcon} alt="Ir para o topo" />
          </button>
        </div>
      )}
    </div>
  );
};

export default ScrollToTopButton;
