import React, { useState } from "react";
import Header from "./components/header/Header";
import "./App.css";
import AboutMe from "./components/about-me/AboutMe";
import Projects from "./components/my-projects/Projects";
import Skills from "./components/skills/Skills";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";
import ScrollToTopButton from "./components/scroll-to-top/ScrollToTopButton";
import { Link } from "react-scroll"; // Importar Link de react-scroll
import { Analytics } from "@vercel/analytics/react";
function App() {
  const [darkMode, setDarkMode] = useState(false);
  const [language, setLanguage] = useState("en"); // "en" for English, "pt" for Portuguese

  const toggleLanguage = () => {
    setLanguage(language === "en" ? "pt" : "en");
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <div className={`App ${darkMode ? "dark-mode" : ""}`}>
      <ScrollToTopButton />
      <Header
        ativar={toggleDarkMode}
        darkMode={darkMode}
        ativarPortugues={toggleLanguage}
        language={language}
      />
      <AboutMe language={language} />
      <Projects darkMode={darkMode} language={language} />
      <Skills language={language} />
      <Contact language={language} />
      <Footer language={language} />
      <Analytics />
    </div>
  );
}

export default App;
