import React, { useState } from "react";
import { Link } from "react-scroll";
import "./Header.css";
import linguaEN from "./header-icons/icon-ingles.png";
import darkModeMobile from "./header-icons/icon-mobile-dark-mode.png";
import lightModeMobile from "./header-icons/icon-mobile-light-mode.png";
import linguaPT from "./header-icons/icon-portugues.png";
import hamburgerOpen from "./header-icons/icon-hamburger.png";
import hamburgerClose from "./header-icons/icon-close.png";

function Header({ ativar, darkMode, ativarPortugues, language }) {
  const [menuAberto, setMenuAberto] = useState(false);

  const abrirMenu = () => {
    setMenuAberto(!menuAberto);
    if (!menuAberto && window.innerWidth <= 645) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  };

  const texts = {
    en: {
      projects: "PROJECTS",
      skills: "SKILLS",
      contact: "CONTACT",
      darkModeButton: darkMode ? "LIGHT MODE" : "DARK MODE",
      languageButton: "EN-PT",
    },
    pt: {
      projects: "PROJETOS",
      skills: "TECNOLOGIAS",
      contact: "CONTATO",
      darkModeButton: darkMode ? "MODO CLARO" : "MODO ESCURO",
      languageButton: "EN-PT",
    },
  };

  const currentTexts = texts[language];

  return (
    <div className="header">
      <p>
        <a href="/" className="logo-link">
          &lt;<span className="nome-full">MATHEUS LEITE</span>
          <span className="nome-initials">ML</span>/&gt;
        </a>
      </p>

      <div>
        <div className="headerMobile">
          <img
            className="darkModeMobileBtn"
            src={darkMode ? lightModeMobile : darkModeMobile}
            alt={darkMode ? "Botão Light Mode" : "Botão Dark Mode"}
            onClick={ativar}
          />
          <img
            className="linguagemBtn"
            src={language === "en" ? linguaEN : linguaPT}
            alt={language === "en" ? "Portuguese Language" : "English Language"}
            onClick={ativarPortugues}
          />
          <img
            className="hamburgerBtn"
            src={menuAberto ? hamburgerClose : hamburgerOpen}
            alt={menuAberto ? "Icon " : "Icon "}
            onClick={abrirMenu}
          />
        </div>
        <ul className={`navbar-ul ${menuAberto ? "open" : ""}`}>
          <li>
            <img
              onClick={abrirMenu}
              className="btnFechar"
              src={hamburgerClose}
            ></img>
          </li>
          <li>
            <Link
              onClick={abrirMenu}
              to="projects"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
            >
              {currentTexts.projects}
            </Link>
          </li>
          <li>
            <Link
            onClick={abrirMenu}
              to="container-skills"
              spy={true}
              smooth={true}
              offset={-10}
              duration={500}
            >
              {currentTexts.skills}
            </Link>
          </li>
          <li>
            <Link
            onClick={abrirMenu}
              to="contact-container"
              spy={true}
              smooth={true}
              offset={-30}
              duration={500}
            >
              {currentTexts.contact}
            </Link>
          </li>
          <li className="btnDarkMode">
            <button onClick={ativar}>{currentTexts.darkModeButton}</button>
          </li>
          <li className="btnLinguagem">
            <button onClick={ativarPortugues}>
              {currentTexts.languageButton}
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Header;
