import "./Contact.css";

function Contact({ language }) {
  const handleEmailClick = () => {
    window.location.href = "mailto:matheus@matheusleite.dev";
  };

  const handleGithubClick = () => {
    window.open("https://github.com/mthaugusto", "_blank");
  };

  const handleLinkedinClick = () => {
    window.open("https://www.linkedin.com/in/augustomath/", "_blank");
  };

  const texts = {
    en: {
      title: "CONTACT",
      emailButton: "E-mail",
      githubButton: "Github",
      linkedinButton: "Linkedin",
    },
    pt: {
      title: "CONTATO",
      emailButton: "E-mail",
      githubButton: "Github",
      linkedinButton: "Linkedin",
    },
  };

  const currentTexts = texts[language];

  return (
    <div className="contact-container">
      <h2>{currentTexts.title}</h2>
      <div className="btn-container">
        <button className="contact-button" onClick={handleEmailClick}>
          {currentTexts.emailButton}
        </button>
        <button className="contact-button" onClick={handleGithubClick}>
          {currentTexts.githubButton}
        </button>
        <button className="contact-button" onClick={handleLinkedinClick}>
          {currentTexts.linkedinButton}
        </button>
      </div>
      <div className="contactMobile">
        <button className="contact-button-mobile" onClick={handleEmailClick}>
          {currentTexts.emailButton}
        </button>
      </div>
    </div>
  );
}

export default Contact;
